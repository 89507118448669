<template>
  <div class="scanning-code chery-body">
    <van-nav-bar :title="title" class="nav-bar" @click-left="onClickLeft">
      <template slot="left">
        <van-icon name="arrow-left" size="18" color="#323F50"/>
      </template>
      <template #right>
        <userNav/>
      </template>
    </van-nav-bar>

    <div class="scanning-code-body" >
      <!--      {{this.number}}-->
      <div class="scanning-code-body-jump" v-show="!photos" >
        <div class="scanning-code-photo"   @click="jump"   >
          <!--       @click="jump"     @click="showPhoto"-->
          <span class="icon-saoyisao iconfont"></span>
        </div>
        <div class="scanning-code-text">扫一扫</div>
      </div>
      <div class="scanning-code-model" v-show="photos">
        <StreamBarcodeReader v-if="scanFlag" @decode="getCode" ></StreamBarcodeReader>
      </div>
    </div>
  </div>
</template>
<script>

import {getDetailByVin} from "@http/APIConfig";
export default {
  components: {
    userNav: () => import('@/components/userNav'),
    StreamBarcodeReader:()=> import('@/components/StreamBarcodeReader')
  },
  data() {
    return {
      number:0,
      selected: 1,
      go: '',
      name: '',
      photos:false,
      VinCode:'LVTDB21B2ND060061',//  李
      //  VinCode:'LVTDB21B8ND096465',//测试环境
      //  VinCode:'LVTDB21B9ND204009',//测试环境
      clientHeight:'',
      clientWidth:'',
      scanFlag:true,
      title:'',
    };
  },
  methods: {
    showPhoto(){
      this.photos = true
      const timer = setInterval(() => {
        this.scanFlag = false;
        setTimeout(() => {
          this.scanFlag = true;
        },500)
      },60*1000*5)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
      })
    },
    onClickLeft() {
      this.$router.push({
        path: '/Main' }
      )
    },
    onBeforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        // 重置表单校验
        return done();
      }
    },
    jump() {
      getDetailByVin({vin: this.VinCode}).then(res => {
        if(res.code==200){
          this.$store.dispatch('emptyProblems')
          this.$store.dispatch('emptyProblemsOffline')


          this.$store.dispatch("setCarInfo", {...res.result,...{vin: this.VinCode}})
          this.$router.push({
            path:  `${this.route}`,query:{
              vin:this.VinCode}
          })
        }
      })
    },
    getCode(e){
      this.VinCode = e
      this.jump()
    },
    getTitle(a){
      switch (a){
        case "/onlineInspection":
          this.title = "在线检验"
          break;
        case "/onlineRework":
          this.title = "在线返工"
          break;
        case "/onlineRecheck":
          this.title = "在线复检"
          break;
        case "/offlineRework":
          this.title = "离线返工"
          break;
        case "/offlineRecheck":
          this.title = "离线复检"
          break;
      }
    }
  },
  mounted() {
    if(this.$route.query.route){
      this.$store.dispatch("setRoute", this.$route.query.route)
      this.route = this.$route.query.route
    }else{
      this.route = this.$store.getters.route
    }
    this.$bus.on('update',e=>{
      this.number = e
    })

    this.getTitle(this.route)
    console.log(this.route)
  }

};
</script>

<style lang="less">
.scanning-code {


  .field-dialog {
    margin-bottom: 15px;
  }

  .dialog-btn {
    width: 127px;
    height: 37px;
    background: #006CDA;
    border-radius: 9px;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 15px;
    display: block;
    margin: 24px auto 36px;
  }
  .scanning-code-body{

    .scanning-code-body-jump{
      padding-top:170px
    }
  }
  .scanning-code-photo {
    text-align: center;
    margin: 0 auto 25px;
    width: 126px;
    height: 126px;
    background: #006CDA;
    box-shadow: 0px 3px 10px 0px rgba(63, 110, 255, 0.4);
    border-radius: 50%;
    .iconfont{
      font-size: 50px;
      color: #fff;
      line-height:120px
    }
  }

  .scanning-code-text {
    font-size: 25px;
    font-weight: 500;
    color: #313E4F;
    text-align: center;
  }
  .scanning-code-model{
    width: 320px;
    margin: 0 auto;
    padding-top: 50px;

  }
}
</style>
