//网络请求工具类
//
//  const Cookies = require('js-cookie');
// const myAxios = require('axios');
// const { Toast } = require('vant');
import Cookies from 'js-cookie';
 import myAxios from 'axios'
import { Toast } from 'vant';
import router from '../router/index'
// debugger
const isDevelop = process.env.NODE_ENV === "development";

myAxios.defaults.timeout = 30000;

// myAxios.defaults.withCredentials = true

// http request 拦截器
myAxios.interceptors.request.use(
    config => {
        //对请求数据加密
        if(config.data){
            config.data = handleSendData(config.data.params);
        }else{
            config.data = config.params
        }

        let token = Cookies.get('token');
        // 1. 请求开始的时候可以结合 vuex 开启全屏 loading 动画
        // console.log(store.state.loading)
        // console.log('准备发送请求...')
        // 2. 带上token

        if (token) {
            config.headers[ 'X-Access-Token' ] = token;
            config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改

        } else {

            Cookies.set('token', '')
            // 重定向到登录页面
            // router.replace('/Login').catch();



        }

       // config.withCredentials = true
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
);

// http response 拦截器
myAxios.interceptors.response.use(
    (response) => {
        let data;
        if(response.hasOwnProperty('data')) {
            data = response
        }else{
            data = response
        }
        return data;
    },
    (error) => {
        return



        // 网络异常
        if (error.code === 'ECONNABORTED' || error.message === 'Network Error' || error.message.indexOf('timeout') !== -1) {
            Toast('网络异常');
            return Promise.reject({
                code: -10000,
                msg: '网络异常',
            });
        }
        if (error && error.response.status) {
            //

            switch (error.response.status) {
                case 401:
                    Toast.fail('请重新登录');
                    Cookies.set('token', '')
                     router.replace('/login').catch();
                    break;
                case 403:
                    Toast.fail('拒绝访问');
                    break;
                case 404:
                    Toast.fail('很抱歉，资源未找到');
                    break;
                case 500:
                    Toast.fail('登录已过期');
                    break;
                case 502:
                    Toast.fail('车辆信息不存在');
                    break;
                case 504:
                    Toast.fail('网络超时');
                    break;
                default:
                    Toast.fail(`未知错误(${error.response.status})!`);
            }
        }else if(error.response === undefined && error.message === 'Network Error'){
            Toast.fail(`网络异常，请稍后重试！`);
        }
        return Promise.reject({
            code: error.response.status,
            msg: error.message,
        });
    }
);


/* 请求头 */
function getHeader() {
    let header = {
        "content-type": "application/x-www-form-urlencoded",
        version: "1.0.0",
        token:   Cookies.get('token'),
    };
    return header;
}

//处理发送的数据，对数据加密
function handleSendData(params) {
    if (isDevelop) {
        // console.log("===== HttpUtils 请求参数 =====", params);
    }
    return params;
}

//处理返回数据，对数据解密
function handleReturnData(res) {
    if (isDevelop && res) {
        // console.log("===== HttpUtils 返回数据 =====", res.data);
    }
    return res;
}

/**
 * function: 显示/隐藏加载框
 * @isShow 显示/隐藏
 * @loadingText 加载框文字
 */
function showLoading(isShow, loadingText) {
    // console.log(isShow);
    if (isShow == false) {
        Toast.clear();
        return;
    }
    if (loadingText) {
        if (loadingText != "" && isShow == true) {
            Toast.loading({
                message: loadingText,
                duration: 0,
                // forbidClick: true,
            });
        }
    }
}

/* 进行请求 */
const request = (url, method, params, loadingText) => {

    showLoading(true, loadingText);
    return new Promise((resolve, reject) => {
        if (method === "GET") {
            myAxios
                .get(url, {
                    params: params,
                    headers: getHeader(),
                })
                .then((res) => {
                   // Toast.clear();
                    resolve(handleReturnData(res));
                })
                .catch((error) => {
                    // Toast.clear();
                    reject(error);
                })
        }
        if (method === "POST") {
            myAxios
                .post(url, {
                    params: params,
                    headers: getHeader(),
                })
                .then((res) => {
                    // Toast.clear();
                    resolve(handleReturnData(res))

                })
                .catch((error) => {
                    // Toast.clear();
                    reject(error);
                });
        }
    });
};

/* get请求 */
export function get2(url, params, loadingText) {
    return request(url, "GET", params, loadingText);
}

/* post请求 */
export function post2(url, params, loadingText) {
    return request(url, "POST", params, loadingText);
}



/*
使用方法 ：

1.在要使用的js文件导入
const HTTP = require('../../../../http/HttpUtils.js');

2. 调用
HTTP.post('url', params,'正在加载...').then(res => {
}).catch(error=>{
});

 */
