import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from 'js-cookie';


import Root from "../views/root/root.vue";
import Login from "../views/login/login.vue";
import Main from "../views/root/main.vue";
import fas from "../views/root/fas.vue";
import yanzheng from "../views/root/scan.vue";
import scan from "../views/scan/index3.vue";
import NotFound from "../views/root/NotFound.vue";
//在线检验

Vue.use(VueRouter);
const routes = [

    { path: "/", name: "main", component: Main },
    { path: "/login", name: "login", component: Login },
    { path: "/sc", name: "sc", component: fas },
    { path: "/scan", name: "sc", component: scan },
    { path: "/verify", name: "sc", component: yanzheng },//进入到验证界面，点击后进入到scan


    {
        path: '/main',
        name: "main",
        component: Main,
    },
    { path: "/vc", name: "vc", component: Root },
    { path: "/NotFound", name: "NotFound", component: NotFound },
    // 所有未定义路由，全部重定向到404页，必须放在最后
    {
        path: '*',
        redirect: '/404'
    },

    {path: "/", name: "login", component: Login},

];

const router = new VueRouter({
    routes,
    mode: 'hash',
    // base: process.env.BASE_URL,
    // /**
    //  * 路由跳转后页面滚动到顶部
    //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
    //  */
    scrollBehavior: () => ({y: 0}),
});


/*

只能永久，需要手动清除
存储：window.localStorage.setItem(name, JSON.stringify(obj))
读取：window.localStorage.getItem(name)
删除：window.localStorage.removeItem(name)
清空：window.localStorage.clear()
*/
// 路由前置守卫
router.beforeEach((to, from, next) => {
    const {meta, name} = to;
    const {isLogin} = meta;
    let token = Cookies.get('token')
    // token不存在时跳转非登录页，重定向到登录页
    if (name == 'vc' || name == 'sc'||name=='yanzheng') {
        return  next();
    }else
    if ((!token && (name !== 'login') && !isLogin)) {
        Cookies.set('token', '')
        next({name: 'login'});
    } else if (token && name === 'login') {
        next({name: 'main'});
    }
    // 其他场景
    else next();
});

export default router;
