<template>
  <div class="bga">
    <van-swipe :autoplay="3000" style="overflow: hidden">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img :src="image.imageUrl" alt="班教授" title="班教授" style="width: 100%;"/>
      </van-swipe-item>
    </van-swipe>

    <div style="margin-top: 20px" @click="photos=true">
      <img style="width: 100%;" src="../../assets/icona.png" alt="">
    </div>
    <div style="margin-top: 20px;position: relative">
      <img style="width: 100%" src="../../assets/iconb.png" alt="">
      <input style="position: absolute;
      z-index: 2;
      font-size: 14px;
    left: 10%;
    bottom: 28%;
    width: 75%;
    height: 30%;
    border: 0;
    padding: 0 15px;
    background: rgba(0,0,0,0);
" type="text" v-model="string" maxlength="19" @input="inputCode" @keydown.enter="getz"/>
      <van-icon
          style="position: absolute;
      z-index: 2;
    right: 10%;
    bottom: 35%;
    border: 0;
    padding: 0 15px;
    background: rgba(0,0,0,0);
"
          @click="getz"
          name="search" size="18" color="#323F50"/>
    </div>

    <div class="scanning-code-modelsw" v-if="photos">
      <div style="position: absolute;right: 20px;top: 20px" @click="photos=false">x</div>
      <StreamBarcodeReader style="width: 90%;margin: 100px auto;height: 45vh" v-if="scanFlag"
                           @decode="getCode"></StreamBarcodeReader>

    </div>
  </div>

</template>

<script>
import {Toast} from 'vant';
import CryptoJS from 'crypto-js'
import {baseUrl, queryByType, search2} from "@/http/APIConfig";

export default {
  name: "root",
  components: {
    StreamBarcodeReader: () => import('@/components/StreamBarcodeReader'),
  },
  data() {
    return {
      images: [],
      string: '',
      photos: false,
      scanFlag: true,


    }
  },
  methods: {
    inputCode() {
      if ((this.string.toString()).length === 19) {
        this.getz()
      }
    },
    getz() {
      let param = {
        type: 2,
        code: this.string
      }
      search2(param).then(res => {

        if (res.code == 200) {
          let string = `https://m.hzmed.top/#/vc?code=${this.string}&verifyCode=${this.string}`
          console.log(string)
          window.location.href = string
        } else {
          Toast('查询失败，请输入正确的防伪码');
        }


      })
    },
    getCode(e) {
      this.VinCode = e
      window.location.href = e
      this.photos = false
    },
    jump() {
      this.photos = true
      this.scanFlag = true
      this.$router.push('/vc')
    },
  },
  created() {
    let type = this.$route.query.type

    let state = this.$route.query.state || 0
    console.log(state)
    console.log(type)
    queryByType(
        {
          type: 1
        }
    ).then(res => {

      res.rows.forEach(item => {
        if (item.categoryType == type) {
          item.imageUrl = 'https://m.hzmed.top/api/' + item.imageUrl
          this.images.push(item)
          this.images.sort((a, b) => a.sortNum - b.sortNum);
        }

      })


      this.$nextTick(() => {
        const container = document.querySelector('.bga');

        setTimeout(() => {
          container.scrollTo({
            top: container.scrollHeight+400,
            behavior: 'smooth'
          });
        }, 2000)
        setTimeout(() => {
          container.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 4000)
      });
    })
  },

}
</script>

<style scoped>
.bga {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow-y: auto; /* 新增 */
}

.scanning-code-modelsw {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  top: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 3;


}
</style>